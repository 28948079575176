<template>
  <div class="room_detail">
    <headerTop :title="`对战${statusText}`" readme="玩法说明" @handleReadme="handleReadme">
      <div class="ck mobile-show" v-if="fightRoom.status > 1" @click="handleSwitch">查看对战{{ showDetail2 ? '结果' : '详情' }}</div>
    </headerTop>
    <div class="detail_info">
      <div class="box_info">
        <p v-if="fightRoom.status == 1"><span>当前回合数：</span>{{ currentRound }}</p>
        <p v-else><span>回合数：</span>{{ fightRoom.roundNumber || 0 }}</p>
        <p><span>总价值：</span><price :price="fightRoom.boxPriceTotal || 0" :size="$store.state.mode == 'pc' ? 'large' : 'small'" /></p>
      </div>
      <div class="roomType" :style="{ color: fightRoom.model == 0 ? 'gold' : 'red' }">{{ fightRoom.model == 0 ? '欧皇' : '非酋' }}</div>
      <div class="copy_btns">
        <div class="ck pc-show" v-if="fightRoom.status > 1" @click="handleSwitch">查看对战{{ showDetail2 ? '结果' : '详情' }}</div>
        <div
          class="cj"
          @click="
            handleAlert('创建', handleCreat);
            playAudio();
          "
        >
          创建相同房间
        </div>
        <div class="fz" @click="handleCopy">复制分享链接</div>
      </div>
    </div>
    <!-- 宝箱轮播图 -->
    <div class="box_list" v-if="boxList.length">
      <div :style="{ opacity: fightRoom.status == 1 ? 0.5 : 1 }" v-for="(item, index) in boxList" :key="index" :class="['box_list_img', { active: num - 1 == index }]" @click="showBoxDetail(item.boxId)">
        <img :src="item.boxImg01" alt="" />
        <price :price="item.boxPrice" />
      </div>
    </div>
    <div class="room_people">
      <img class="vs pc-show" src="../../assets/image/vs.png" alt="" />
      <img class="vs mobile-show" src="../../assets/image/vs1.png" alt="" />
      <lotterySeat class="room_seat" v-for="(item, i) in seats" :key="i" :fightRoom="fightRoom" :playerId="item.playerId" :showDetail="showDetail2" :fightResult="fightResult" :seat="item" :currentRound="currentRound" @seatReady="seatReady" @myAllEnd="onMyAllEnd" />
    </div>
    <boxDetail v-if="showDetail.showDetail" :showDetail="showDetail" :boxId="boxDetailId"></boxDetail>
  </div>
</template>

<script>
import lotterySeat from '@/components/pkHome/lotterySeat.vue';
import {
  audience,
  getUserInfo,
  ApiSeatReady,
  Playthegame, //开始游戏
  Gameover, //游戏结束
  createFight,
  joinFightverify,
  getBoxList,
} from '@/api/index';
import Socket from '@/utils/socket';

export default {
  data() {
    return {
      showDetail: { showDetail: false },
      boxDetailId: null,
      uid: '',
      num: 0,
      id: this.$route.query.id,
      roundCount: null,
      fightResult: new Map(),
      fightRoom: {
        boxData: [],
      }, // 对战房间信息
      seats: {}, // 对战房间信息
      winnerId: null,
      // 完成抽奖的人数
      allEndNumber: 0,
      currentRound: 1,
      showDetail2: false,
      currentBoxList: [],
      currentTotalMoney: 0,
      boxPage: {
        page: 1,
        size: 10,
        gameMouldId: 2,
        orderByFie: 2,
      },
    };
  },
  components: { lotterySeat },
  created() {
    this.$bus.$on('num', data => {
      this.num = data;
    });
    this.uid = this.$store.state.USER_INFO.userId;
    if (this.uid) return;
    // 请求api获取用户信息
    getUserInfo().then(res => {
      if (res?.data?.code == 200) {
        this.uid = res?.data?.data?.userId;
        this.$store.commit('USER_INFO', res?.data?.data);
        // 建立ws连接
      } else {
        this.$message({
          message: '获取用户信息失败，请重新登录',
          type: 'warning',
        });
      }
    });
  },
  mounted() {
    this.initData();
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.roundCount);
    this.$socket?.close();
    next();
  },
  watch: {
    '$route.query': {
      handler(val) {
        if (val.id) {
          // 初始化数据
          this.id = val.id;
          this.allEndNumber = 0;
          this.fightResult = new Map();
          this.fightRoom = { boxData: [] };
          this.seats = {};
          this.winnerId = null;
          this.currentRound = 1;
          clearInterval(this.roundCount);
          this.roundCount = null;
          this.$socket?.close();
          this.$bus.$on('num', data => {
            this.num = data;
          });
          this.initData();
        }
      },
    },
    fightRoom: {
      handler(newValue) {
        if (newValue.status == 1) {
          this.roundCount = setInterval(() => {
            console.log('回合数', this.currentRound, this.fightRoom.roundNumber);
            if (this.currentRound < this.fightRoom.roundNumber) this.currentRound++;
          }, 4000);
        }
      },
      deep: true,
    },
  },
  computed: {
    statusText() {
      const opt = { 0: '准备中', 1: '进行中', 2: '已结束', 3: '已结束' };
      return opt[+this.fightRoom.status] || '';
    },
    boxList() {
      let arr = [];
      let obj = this.fightRoom?.boxData || {};
      for (let key in obj) {
        for (let i = 1; i <= obj[key].number; i++) {
          arr.push(obj[key]);
        }
      }
      return arr;
    },
  },
  methods: {
    showBoxDetail(id) {
      this.boxDetailId = id;
      this.showDetail.showDetail = true;
    },
    initData() {
      audience(this.id).then(res => {
        if (!res) return;
        if (res.data.code == 200) {
          let data = res.data.data;
          this.currentRound = data.currentRound;
          //整理数据并渲染
          this.setFightDetail(data);
        } else if (res.data.code == 601) {
          this.connectWs();
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
          });
        }
      });
    },
    playAudio() {
      this.$store.commit('playAudio');
    },

    handleAlert(btn, callback) {
      const icon = require('../../assets/svgs/jinbi.svg');
      getBoxList(this.boxPage).then(res => {
        if (res.data.code == 200) {
          this.currentBoxList.push(...res.data.data.list);
        }
        if (res.data.data.list.length == this.boxPage.size) {
          this.boxPage.page++;
          this.handleAlert(btn,callback);
        } else {
          this.boxPage.page = 1;
          let key;
          for (key in this.fightRoom.boxData) {
            let arr = this.currentBoxList.filter(item => {
              return item.boxId == this.fightRoom.boxData[key].boxId;
            });
            if (arr.length == 0) {
              this.isError = true;
              return false;
            }
            this.currentTotalMoney += arr[0].price * this.fightRoom.boxData[key].number;
          }
          let msg = '';
          if (this.currentTotalMoney != this.fightRoom.boxPriceTotal) {
            msg = `<div class="message">箱子价格存在变动，确定消耗<span><img src="${icon}" />${this.currentTotalMoney}</span>吗？</div>`;
          } else {
            msg = `<div class="message">确定消耗<span><img src="${icon}" />${this.currentTotalMoney}</span>吗？</div>`;
          }
          this.$confirm(msg, '提示', {
            confirmButtonText: btn,
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true,
            confirmButtonClass: 'active_btn',
            center: true,
          })
            .then(() => {
              callback?.();
              this.currentBoxList = [];
              this.currentTotalMoney = 0;
            })
            .catch(() => {
              this.playAudio();
              this.currentBoxList = [];
              this.currentTotalMoney = 0;
            });
        }
      });
    },
    // 准备游戏
    seatReady() {
      if (this.$store.state.LOGIN_IS_SHOW) {
        this.$router.push('/login');
      } else {
        this.handleAlert('加入', this.verifyReady);
      }
    },
    Ready(fid) {
      ApiSeatReady(fid).then(res => {
        if (res?.data?.code == 200) {
          getUserInfo().then(res => {
            this.$store.commit('USER_INFO', res?.data?.data || {});
          });
          this.initData();
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
          });
        }
      });
    },
    //确定准备
    verifyReady() {
      this.playAudio();
      joinFightverify({ fightId: this.fightRoom.id }).then(res => {
        if (res?.data?.code == 200) {
          if (res.data.msg == '已报名，再次进入房间。') {
            this.$message({
              type: 'warning',
              message: '已在房间内，请勿重复操作',
            });
          } else {
            this.Ready(this.fightRoom.id);
          }
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
          });
        }
      });
    },
    handleSwitch() {
      this.playAudio();
      this.showDetail2 = !this.showDetail2;
    },
    // 复制分享链接
    handleCopy() {
      this.playAudio();
      // 当前地址栏的URL
      this.$copyText(window.location.href).then(() => {
        this.$message({
          message: '复制成功',
          type: 'success',
        });
      });
    },
    handleReadme() {
      this.$bus.$emit('is_illustrate', true);
      let timer = setTimeout(() => {
        this.$bus.$emit('nr_illustrate', { cont: 'roomDetail' });
        clearTimeout(timer);
      }, 100);
      this.playAudio();
    },
    handleCreat() {
      this.playAudio();
      let boxObj = {};
      let key;
      for (key in this.fightRoom.boxData) {
        boxObj[key] = this.fightRoom.boxData[key].number;
      }
      createFight({
        boxIdAndNumber: boxObj,
        model: this.fightRoom.model,
        playerNumber: this.fightRoom.playerNum,
      }).then(res => {
        if (res?.data?.code == 200) {
          // 提示
          this.$message({
            type: 'success',
            message: '创建成功',
          });
          getUserInfo().then(res => {
            if (res?.data?.code == 200) {
              this.userInfo = res?.data?.data || {};
              this.$store.commit('USER_INFO', res?.data?.data);
            } else {
              this.$store.commit('LOGIN_IS_SHOW', true);
            }
          });
          // 跳转
          let timer = setTimeout(() => {
            this.$router.replace({
              name: 'roomDetail',
              query: {
                id: res?.data?.data.id,
              },
            });
            clearTimeout(timer);
          }, 200);
        } else {
          console.log('api/fight/createFight 异常');
          this.$message({
            type: 'warning',
            message: res.data.msg,
          });
        }
      });
    },
    onMyAllEnd() {
      this.allEndNumber++;
      if (this.allEndNumber == this.seats.length) {
        console.log('去除定时器');
        clearInterval(this.roundCount);
        let timer = setTimeout(() => {
          this.fightRoom.status = 2;
          Gameover(this.fightRoom.id).then(() => {});
          clearTimeout(timer);
        }, 1000);
      }
    },
    fightBegin() {
      Playthegame(this.id).then(res => {
        if (res.data.code != 200) {
          this.$message({
            type: 'warning',
            message: res.data.msg,
          });
        }
      });
    },
    roomIsFull(seats) {
      let flag = true;
      for (let index = 0; index < seats.length; index++) {
        let seat = seats[index];
        if (seat.status == '0' || seat.status == '1') {
          flag = false;
          break;
        }
      }
      return flag;
    },
    roomIsReady(seats) {
      let flag = true;
      for (let index = 0; index < seats.length; index++) {
        let seat = seats[index];
        if (seat.status != '2') {
          flag = false;
          break;
        }
      }
      return flag;
    },
    //建立ws连接
    connectWs() {
      this.$socket = new Socket({
        url: '/ws/fight/room/' + this.uid + '/' + this.id,
        watchLogin: true,
        onmessage: msg => {
          if (msg.key == 'FIGHT_ROOM_INFO') {
            // 房间信息
            let fight = msg?.data || {};
            let seats = fight.seats;
            this.fightRoom = fight;
            this.seats = seats;
            this.winnerIds = fight.winnerIds;
            console.log('房间信息===>', this.fightRoom);

            if (this.roomIsReady(seats)) this.fightBegin();
          } else if (msg.key == 'FIGHT_RESULT') {
            if (msg?.data?.currentRound) this.currentRound = msg.data.currentRound;
            this.setFightDetail(msg.data);
          }
        },
      });
    },

    //整理数据
    setFightDetail(vo) {
      // 整理数据
      let fightRes = vo.fightResult;
      let fightBox = vo.fightBoxVOList;

      // 结果集补充宝箱信息
      for (let record of fightRes) {
        for (let box of fightBox) {
          if (record.boxId == box.boxId) {
            record.boxInfo = box;
            break;
          }
        }
      }

      // 结果集按人分组
      let fightResult = new Map();
      let seats = vo.fight.seats;

      let newSeats = [];
      for (let index = 0; index < seats.length; index++) {
        if (vo.winnerIds.includes(seats[index].playerId)) {
          seats[index].isWinner = true;
        } else {
          seats[index].isWinner = false;
        }

        let playerOwnList = [];
        for (let record of fightRes) {
          if (record.userId == seats[index].playerId) {
            playerOwnList.push(record);
          }
        }
        newSeats.push(seats[index]);
        fightResult.set(seats[index].playerId, playerOwnList);
      }
      this.fightResult = fightResult;
      this.winnerId = vo.WinnerId;
      this.seats = newSeats;
      this.fightRoom = { ...vo.fight, seats: newSeats };
      if (this.fightRoom.status == 2 || this.fightRoom.status == 3) {
        this.lotteryEnd = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.room_detail {
  margin: 0 @pcSpace;
  @media @max750 {
    .flex-column;
    height: 100%;
    margin: 0 10px;
  }
  .header_top {
    .ck {
      left: 0;
      .abs-ct;
      font-size: 12px;
      .sc(12px, @main);
      text-decoration: underline;
    }
  }
  .detail_info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    @media @max750 {
      margin-bottom: 10px;
    }
    .box_info {
      .flex-center;
      p {
        .flex-center;
        .sc(18px, #fff);
        white-space: nowrap;
        margin-right: 40px;
        @media @max750 {
          font-size: 12px;
          margin-right: 20px;
          span {
            font-size: 12px;
          }
        }
      }
    }
    .copy_btns {
      .flex-a-center;
      & > div {
        .sc(16px, #fff);
        margin-right: 20px;
        white-space: nowrap;
        border-bottom: 3px solid transparent;
        @media @max750 {
          font-size: 12px;
          margin-right: 12px;
          border-bottom-width: 2px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .ck {
        border-bottom-color: @jifen;
      }
      .cj {
        border-bottom-color: @jinbi;
      }
      .fz {
        border-bottom-color: @main;
      }
    }
  }
  .box_list {
    .flex-a-center;
    width: 100%;
    padding: 14px;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    background: #0d1214;
    box-sizing: border-box;
    border: 1px solid @main;
    @media @max750 {
      padding: 10px;
    }
    &::-webkit-scrollbar {
      height: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: @main;
      border-radius: 5px;
    }
    .box_list_img {
      margin-right: 10px;
      .flex-column-center;
      &:last-child {
        margin-right: 0;
      }
      img {
        .wh(70px, 70px);
        @media @max750 {
          .wh(50px, 50px);
        }
      }
    }
    .box_list_money {
      margin: 0 20px;
      .flex-column-center;
      .total_price {
        .sc(20px, #fff);
        margin-bottom: 4px;
        white-space: nowrap;
      }
      @media @max750 {
        width: 100px;
        height: 25px;
        font-size: 10px;
        margin: 10px auto 0;
      }
    }
  }
  .room_people {
    width: 100%;
    display: flex;
    padding: 14px 0;
    position: relative;
    justify-content: space-between;
    @media @max750 {
      flex: 1;
      min-height: 40vh;
      padding: 10px 0 0;
    }
    .vs {
      top: 0;
      left: 0;
      .wh(100%, 372px);
      position: absolute;
      @media @max750 {
        height: 100%;
      }
    }
    .room_seat {
      width: 33%;
      overflow: hidden;
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      @media @max750 {
        width: 32%;
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }
  .active {
    opacity: 1 !important;
  }
}
</style>
