import { render, staticRenderFns } from "./lotterySeat.vue?vue&type=template&id=105c83fa&scoped=true"
import script from "./lotterySeat.vue?vue&type=script&lang=js"
export * from "./lotterySeat.vue?vue&type=script&lang=js"
import style0 from "./lotterySeat.vue?vue&type=style&index=0&id=105c83fa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "105c83fa",
  null
  
)

export default component.exports